import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Button from "common/Button";
import { getImagePath } from "utils/assetsHelper";
import Swipe from "common/SwipeHandler";

class AboutWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCardIndex: 0
    };
    this.scrollItems = {};
  }

  componentDidMount() {
    if (document.getElementsByClassName("about-work-main-block")) {
      var swiper = new Swipe(
        document.getElementsByClassName("about-work-main-block")[0]
      );
      swiper.onLeft(() => {
        //alert("left" + `${this.props.aboutWorkInfo.length} ${this.state.selectedCardIndex}`)
        if (this.props.aboutWorkInfo.length - 1 > this.state.selectedCardIndex)
          this.setState({
            selectedCardIndex: this.state.selectedCardIndex + 1
          });
        //setIsCollapsed(true);
      });
      swiper.onRight(() => {
        if (this.state.selectedCardIndex > 0)
          this.setState({
            selectedCardIndex: this.state.selectedCardIndex - 1
          });
        //setIsCollapsed(false);
      });
      swiper.run();
    }
  }

  handleScroll = (scrollItem = "") => {
    // let selectedScrollItem = this.scrollItems[scrollItem];
    // if (selectedScrollItem !== undefined && selectedScrollItem !== null)
    //selectedScrollItem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  handleChangeCard = cardIndex => {
    this.setState({ selectedCardIndex: cardIndex });
  };

  render() {
    const { aboutWorkTitle, aboutWorkInfo, currentScreenWidth } = this.props;
    const { selectedCardIndex } = this.state;
    return (
      <div className="about-work-wrapper">
        <img
          src={getImagePath("/section-splitter-icon.svg")}
          className="section-splitter-2"
          alt=""
        />
        <div className="about-work-container">
          <span className="about-work-title">{aboutWorkTitle}</span>
          <div
            className="about-work-main-block"
            // style={{
            //   backgroundImage:
            //     'url("' + getImagePath("/about-us-splitter.svg") + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
            //   backgroundSize: "100% 100%"
            // }}
          >
            {aboutWorkInfo.map((item, i) => (
              <div
                className="about-work-item-wrpper"
                style={{
                  transform: `${
                    currentScreenWidth < 769
                      ? `translate3d(-${selectedCardIndex * 100}%, 0px, 0px)`
                      : "none"
                  }`,
                  transitionDuration: "350ms",
                  marginBottom: i !== aboutWorkInfo.length - 1 ? "0" : '-200px',
                  position: 'relative'
                }}
                key={i}
              >
                <div
                  className="about-work-item"
                  id={`item-${i}`}
                  key={i}
                  onClick={() => this.handleScroll(item.title)}
                  ref={el => (this.scrollItems[item.title] = el)}
                  style={{
                    flexDirection: i % 2 !== 0 ? "row-reverse" : "row",
                    height: currentScreenWidth < 769 ? "100%" : '450px'
                  }}
                >
                  <div
                    className="about-work-left"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // marginLeft:
                      //   currentScreenWidth < 769 ? 0 : item.style.marginLeft,
                      // marginRight:
                      //   currentScreenWidth < 769 ? 0 : item.style.marginRight,
                      marginTop: currentScreenWidth < 769 ? "5px" : 0,
                      padding: i % 2 === 0 ? "0 90px 0 0" : "0 0 0 90px",
                    }}
                  >
                    <span className="work-item-title">{item.title}</span>
                    <span className="work-item-subtitle">
                      {item.subTitle}
                    </span>
                    <span className="work-item-content">{item.content}</span>
                    <Button
                      subClass="demo-button"
                      text={"Request a Demo"}
                      handleButtonClick={this.props.handleButtonClick}
                    />
                  </div>
                  {currentScreenWidth > 768 && (
                    <div>
                      <img
                        src={getImagePath("/about-us-splitter.svg")}
                        className="about-us-splitter"
                        alt=""
                      />
                    </div>
                  )}
                  <div className="about-work-right"
                    style={{
                      // width: currentScreenWidth < 769 ? '100%' : item.style.imgWidth,
                      // marginLeft: currentScreenWidth < 769 ? 0 : item.style.left,
                      padding: i % 2 === 0 ? "0 0 0 90px" : "0 90px 0 0"
                    }}
                  >
                    <img
                      src={getImagePath(item.imgUrl)}
                      alt="work-icon"
                      className="about-work-icon"
                      style={{
                        height: currentScreenWidth > 769 ? item.imgHeight : '180px',
                        width: currentScreenWidth > 769 ? item.imgWidth : "90%"
                      }}
                    />
                  </div>
                </div>
                {i === aboutWorkInfo.length - 1 && currentScreenWidth > 769 &&
                  <div className="about-work-item-bottom"
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '160px',
                      right: 0,
                      bottom: 0,
                      background: '#fff'
                    }}
                  >
                  </div>
                }
              </div>
            ))}
          </div>
          {currentScreenWidth < 769 && (
            <ul className="control-circle-dots">
              {aboutWorkInfo.map((item, i) => (
                <li
                  key={i}
                  className={`circle-dot ${selectedCardIndex === i &&
                    "selected"}`}
                  style={{
                    background:
                      selectedCardIndex === i
                        ? "radial-gradient(#1ca7e5, #65d7c8)"
                        : "#eeeff2",
                    opacity: selectedCardIndex === i ? 1 : 0.3
                  }}
                  onClick={() => this.handleChangeCard(i)}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

AboutWork.defaultProps = {
  aboutWorkTitle: "What We do",
  aboutWorkInfo: [
    {
      title: "classifAI",
      subTitle: "Automated Defect Classification",
      content:
        "Enabled by Computer Vision, classifAI is a self-learning solution that reviews and classifies defects to eliminate misclassifications and overkill.",
      buttonText: "Request a Demo",
      imgUrl: "/automated-defect.png",
      imgHeight: "270px",
      imgWidth: "115%"
    },
    {
      title: "causifAI",
      subTitle: "Streamlined Root Cause Analysis",
      content:
        "Using Big Data Analytics, causifAI software scans through terabytes of factory data to identify equipment or process issues that caused the defect.",
      buttonText: "Request a Demo",
      imgUrl: "/streamlined-root.png",
      imgHeight: "270px",
      imgWidth: "100%"
    },
    {
      title: "predictAI",
      subTitle: "Intelligent Defect Prediction",
      content:
        "Powered by IoT analytics, predictAI is a powerful failure prediction tool that monitors equipment in real-time and predict anomalies at their source.",
      buttonText: "Request a Demo",
      imgUrl: "/intelligent.png",
      imgHeight: "270px",
      imgWidth: "100%"
    }
  ]
};

AboutWork.propTypes = {
  aboutWorkTitle: PropTypes.string,
  aboutWorkInfo: PropTypes.array
};

export default AboutWork;
