import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import Button from 'common/Button';

function Careers(props) {
	const { title, jobList, currentScreenWidth } = props;

	const handleJobSelect = redirectionUrl => {
		window.open(redirectionUrl, '_blank');
	};

	const getEachJobItem = () => {
		return (
			<div className='careers-wrapper-block'>
				{jobList.map((job, i) => (
					<div className='job-card' key={i}>
						<img src={getImagePath(job.jobIcon)} className='job-icon' alt='' />
						<img src={getImagePath('/red-right-arrow.png')}
							className='responsive-job-selection'
							onClick={() => handleJobSelect(job.redirectionUrl)}
							alt=''
						/>
						<div className='job-content-wrapper'>
							<span className={`job-title ${currentScreenWidth < 768 && job.title.length > 25 ? 'split-title' : 'normal'}`}>{job.title}</span>
							<span className='job-type'>
								{job.type.map((eachType, i) => (
									<Button text={eachType} subClass={'each-job-type'} key={i} />
								))}
							</span>
							{/* <span className='job-dec-title'>{'What you will do:'}</span> */}
							<span className='job-description'>
								{job.description.map((eachdes, i) => (
									<div className='job-desc-item' key={i}>
										{/* <span className='each-desc-pointer'>*</span> */}
										<img src={getImagePath('/dot-icon.png')} className='each-desc-pointer' alt='' />
										<span className='each-job-desc' key={i}>
											{eachdes}
										</span>
									</div>
								))}
							</span>
						</div>
						<div className='job-more-button'>
							<Button handleButtonClick={() => handleJobSelect(job.redirectionUrl)} />
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<div className='careers-main-block'>
			<img src={getImagePath('/section-splitter-icon.svg')} className='careers-section-splitter' alt='' />
			<span className='careers-title-block'>{title}</span>
			{getEachJobItem()}
			<div className='apply-job-block'>
				Apply{' '}
				<a href='https://angel.co/company/sixsense/jobs'>
					here
				</a>{' '}
				or drop us and email with your CV and Github link at{' '}
				<span className='contact-mail'><a href="mailto:hello@sixsense.ai"> hello@sixsense.ai,</a></span> We'd love to hear from you!
			</div>
		</div>
	);
}

Careers.defaultProps = {
	title: 'We are Hiring',
	jobList: [
		{
			id: 1,
			title: 'Lead Data Scientist',
			jobIcon: '/Jobs/job-scientist.png',
			redirectionUrl:
				'https://angel.co/company/sixsense/jobs/591217-lead-data-scientist-computer-vision-and-machine-learning',
			type: ['Full Time'],
			description: [
				'Conduct statistical analysis of manufacturing data for exploratory analysis',
				'Measure model performance...'
			]
		},
		{
			id: 2,
			title: 'Full-Stack Developer',
			jobIcon: '/Jobs/full-stack-developer.png',
			redirectionUrl: 'https://angel.co/company/sixsense/jobs/574990-full-stack-developer',
			type: ['Full Time'],
			description: [
				'Build a scalable Web Application for an AI platform',
				'Develop the UI and define the functionality/features available to the…'
			]
		},
		{
			id: 3,
			title: 'Data Scientist - Computer Vision & Machine Learning',
			jobIcon: '/Jobs/data-engineer.png',
			redirectionUrl:
				'https://angel.co/company/sixsense/jobs/574993-data-scientist-computer-vision-and-machine-learning',
			type: ['Full Time', 'Intern'],
			description: [
				'Develop and evaluate state-of-the-art vision algorithms for defect identification ',
			]
		}
	]
};

Careers.propTypes = {
	jobList: PropTypes.array
};

export default Careers;
