import React, { Component } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getImagePath } from 'utils/assetsHelper';
import CarouselMonials from './CarouselMonials';
import SwipeMonials from './SwipeMonials';

/**
 * Courousel DOC's ******Need to remove in future
 *
 * https://www.npmjs.com/package/react-responsive-carousel
 * SandBox demo: https://codesandbox.io/s/lp602ljjj7
 *
 * Customizable Demo Docs: http://react-responsive-carousel.js.org/#demos,
 * http://react-responsive-carousel.js.org/storybook/index.html?selectedKind=Carousel&selectedStory=custom%20transition%20time%20%281000ms%29&full=0&down=1&left=1&panelRight=0&downPanel=kadirahq%2Fstorybook-addon-actions%2Factions-panel
 *
 */

class SixSenseCarousel extends Component {
	constructor(props) {
    super(props);
    this.state = {
      selectedCardIndex: 0
    };
  }

	render() {
		const { currentScreenWidth, peopleSayData } = this.props;

		return (
			<div className='sixsense-carousel-wrapper'>
				<div className='testimonial-header-block'>
					<img src={getImagePath('/people-say-quotes.svg')} className='people-say-quotes' alt=""/>
					<span className='testimonial-header'>{'People Say'}</span>
				</div>
				{currentScreenWidth > 768
					? <CarouselMonials peopleSayData={peopleSayData} />
					: <SwipeMonials peopleSayData={peopleSayData}  /> }
			</div>
		);
 	}
}

SixSenseCarousel.defaultProps = {
	peopleSayData: [
		{
			id: 1,
			name: 'Process Integration Engineer',
			description:
				'Thanks for the great demo of six sense. This will be very valuable tool for process integration engineer to get to the root cause understanding quickly. Six sense removes human biases and provides a good starting point towards problem solving. Definitely deserves a trial at our company. Awesome job at solving a real problem for engineer like me.',
			style: {
				paddingBottom: '20px'
			}
		},
		{
			id: 2,
			name: 'Process Engineer',
			description:
				'As a former semiconductor process engineer, I found SixSense solution is a great way to capture the root cause of issues in a very efficient manner. Usually I may need hours even days to find the actual problem & it may vary to different individual’s justification, with SixSense we quickly identify which alerts from which machine cause the defect in a real time manner which is truly amazing. The time saving will be tremendous & it will increase our productivity significantly.',
			style: {
				paddingBottom: '20px'
			}
		},
		{
			id: 3,
			name: 'Mr. Rajan Rajgopal',
			personExpIcon: '/rajan-exp-icon.png',
			description:
				'From my assessment your idea for developing an algorithm to predict yield for a semiconductor product real time is something that is a gap in the industry and hence a great product to develop. Many fabs in the world struggle with information that will allow them to "contain" an excursion in the line and then quickly  come up with a root cause so the line can be restarted or the rouge tool be identified or fixed and I beleive your tool would allow engineers to quickly zoom in to the issue and develop a fix.',
			style: {
				paddingBottom: '0px'
			}
		},
		{
			id: 4,
			name: 'Process Engineer',
			description:
				'First of all very glad to take part in the product demo. The demonstration is great, the software is simple , friendly user and it contains 90% or more analysis tools function. It links the machine parameters to process data, measurement data , defects data and electrical data. It could help engineer to do troubleshooting in no time and do predictions to prevent excursion. Hope to have this kind of product as an  assistant for daily task. Time and cost saving tool!!!',
			style: {
				paddingBottom: '20px'
			}
		}
	]
};

SixSenseCarousel.propTypes = {
	peopleSayData: PropTypes.array
};

export default SixSenseCarousel;
