import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';

function Footer(props) {
	const { handleFooterNavigation, tapTop, handleToHome } = props;

	const handleRedirection = actionType => {
		let url;
		if (actionType === 'YOUTUBE') url = 'https://www.youtube.com/watch?v=2Py4frlOmTw';
		window.open(url, '_blank');
	};

	const handleTapTop = () => {
		handleToHome();
	}

	return (
		<div
			className='footer-main-block'
			style={{
				backgroundImage: 'url("' + getImagePath('/footer-bg.svg') + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
				backgroundSize: '100%',
				backgroundRepeat: 'no-repeat',
				height: '280px'
			}}
		>
			<div className='f-main-contents-container'>
				<div className='f-social-info'>
					{/* <div className='f-social-main-icon' /> */}
					<div className='f-social-media-icon-block'>
						<img src={getImagePath('/six-sense-logo-old.svg')} alt='logo' className='f-fb-icon' />
						<div className='f-tr-icon'>SixSense</div>
						<img
							src={getImagePath('/ytbe-icon.svg')}
							alt='yb'
							className='f-ytb-icon'
							onClick={() => handleRedirection('YOUTUBE')}
						/>
					</div>
					<div className='f-copy-right-block'>
						Copyright © 2019. Made with <img src={getImagePath('/love-icon.svg')} alt="" /> in Singapore!
					</div>
				</div>
				<div className='f-main-sections-info'>
					<div className='f-inner-section-1'>
						<span onClick={() => handleFooterNavigation('home')}>{'Home'}</span>
						<span onClick={() => handleFooterNavigation('about')}>{'Who We Are'}</span>
						<span onClick={() => handleFooterNavigation('aboutwork')}>{'What we do'}</span>
						<span onClick={() => handleFooterNavigation('team')}>{'Meet Our Founders'}</span>
					</div>
					<div className='f-inner-section-2'>
						<span onClick={() => handleFooterNavigation('peoplesay')}>{'People Say'}</span>
						<span onClick={() => handleFooterNavigation('sixsenselife')}>{'Life At SixSense'}</span>
						<span onClick={() => handleFooterNavigation('careers')}>{'We’re Hiring!'}</span>
						<span onClick={() => handleFooterNavigation('contact')}>{'Get in Touch'}</span>
					</div>
				</div>
			</div>

			<div
				className={"tap-top " + (tapTop ? 'fadeIn' : 'fadeOut')}
				onClick={() => handleTapTop()}
			>
				<img
					className='tap-top-icon'
					src={getImagePath('/arrow-up.png')}
					alt='icon'
				/>
			</div>
		</div>
	);
}

Footer.defaultProps = {
	tapTop: false 
};

Footer.propTypes = {
	tapTop: PropTypes.bool,
	handleToHome: PropTypes.func
};

export default Footer;
