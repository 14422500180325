import React, { Component } from 'react';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItem: this.props.scrolledSection ? this.props.scrolledSection : 'home',
			showMenu: false,
			hasShowNormalTheme: false
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.selectedItem !== nextProps.scrolledSection) {
			this.setState({
				selectedItem: nextProps.scrolledSection
			})
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 80) {
				this.setState({ hasShowNormalTheme: true });
			} else {
				this.setState({ hasShowNormalTheme: false });
			}
		});
	}

	handleSelectItem = (item, windowWidth) => {
		let { showMenu } = this.state;
		this.setState({
			selectedItem: item.value,
			showMenu: !windowWidth ? !showMenu : showMenu
		});
		this.props.triggerHandleScroll(item.value);
	};

	handleToggleAction = () => {
		const { showMenu } = this.state;
		if (!showMenu)
			document.body.style.overflow = 'hidden';
		else
			document.body.style.overflow = 'auto';
		this.setState({ showMenu: !showMenu });
	};

	render() {
		const { selectedItem, showMenu, hasShowNormalTheme } = this.state;
		const { contentItems } = this.props;
		const windowWidth = window.innerWidth > 770;
		return (
			<header className={`header-wrapper ${(hasShowNormalTheme || showMenu) && 'enable-normal'}`}
			>
				<nav className='header-main-block'>
					<div className='header-icon'>
						{hasShowNormalTheme && (
							<img
								src={getImagePath('/six-sense-logo-old.svg')}
								className='sixsense-icon'
								alt='SixSense'
							/>
						)}
					</div>
					<div className='header-primary-content'>
						{(windowWidth || showMenu) && (
							<div
								className='primary-content-block'
								style={{
									height: showMenu ? '100%' : '100%'
								}}
							>
								{/* <span className='primary-item active'>
								{/* <a data-scroll='scrollTo' href='#home'>
									{'Home'}
								</a> */}
								{/* </span> */}

								{contentItems.map((item, i) => (
									<div
										className={`primary-item ${
											selectedItem === item.value
												? hasShowNormalTheme
													? 'acive-normal'
													: 'active'
												: ''
											}`}
										id={i}
										key={i}
										onClick={() => this.handleSelectItem(item, windowWidth)}
									>
										<span className={`item-block ${hasShowNormalTheme && 'enable-item'}`}>
											{item.label}
										</span>
									</div>
								))}
							</div>
						)}
					</div>
					{/* <div className='header-secondary-content'>
						<div>{'login'}</div>
						<div>{'Sign Up'}</div>
					</div>*/}
					<div className='responsive-header-icon' onClick={this.handleToggleAction}>
						<img
							className='header-nav-icon'
							src={showMenu ? getImagePath('/close-icon.svg') : getImagePath('/side-bar-icon.svg')}
							alt='icon'
						/>
					</div>
				</nav>
			</header>
		);
	}
}

export default Header;
