import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { getImagePath } from "utils/assetsHelper";

function Investors(props) {
  const { currentScreenWidth, joinUsTitle } = props;
  const investImg = currentScreenWidth > 769 ? getImagePath('/investors.png') : getImagePath('/investors-mobile.png');
  return (
    <div className="investors-main-block">
      <img
        src={getImagePath("/section-splitter-icon.svg")}
        className="investor-section-splitter"
        alt=""
      />
      <span className="investors-title">{joinUsTitle}</span>
      <div className="investors-details">
        <img
          src={investImg}
          style={{
            margin: '20px',
            mixBlendMode: "multiply",
            maxWidth: '760px'
          }}
          alt=""
        ></img>
      </div>
    </div>
  );
}

Investors.defaultProps = {
  joinUsTitle: "Backed By"
};

Investors.propTypes = {
  joinUsTitle: PropTypes.string
};

export default Investors;
