import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import EmployOpinion from './EmployOpinion';
import ResponsiveEmployOpinion from './ResponsiveEmployOpinion';
import Swipe from 'common/SwipeHandler';

class SixSenseLife extends Component {
	state = {
		selectedGridIndex: 0,
		selectedCardIndex: 0,
		hasShowDesFull: false
	};

	componentDidMount() {
		if (document.getElementsByClassName('join-us-advantage-container')) {
			let swiper = new Swipe(document.getElementsByClassName('join-us-advantage-container')[0]);
			swiper.onLeft(() => {
				//alert("left" + `${this.props.aboutWorkInfo.length} ${this.state.selectedCardIndex}`)
				if (this.props.sixSenseAdvantagesGallery.length - 1 > this.state.selectedCardIndex)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex + 1 });
			});
			swiper.onRight(() => {
				if (this.state.selectedCardIndex > 0)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex - 1 });
			});
			swiper.run();
		}

		if (document.getElementsByClassName('life-grid-wrapper')) {
			let swiper = new Swipe(document.getElementsByClassName('life-grid-wrapper')[0]);
			swiper.onLeft(() => {
				//alert("left" + `${this.props.aboutWorkInfo.length} ${this.state.selectedCardIndex}`)
				if (this.props.sixSenseLifeGallery.length - 1 > this.state.selectedGridIndex)
					this.setState({ selectedGridIndex: this.state.selectedGridIndex + 1 });
			});
			swiper.onRight(() => {
				if (this.state.selectedGridIndex > 0)
					this.setState({ selectedGridIndex: this.state.selectedGridIndex - 1 });
			});
			swiper.run();
		}
	}

	handleChangeCard = (cardIndex) => {
		this.setState({ selectedCardIndex: cardIndex });
	}

	handleChangeGrid = (gridItem) => {
		this.setState({ selectedGridIndex: gridItem });
	}

	handleDesResize = () => {
		this.setState({ hasShowDesFull: !this.state.hasShowDesFull });
	}

	getSixSenseLifeGrid = () => {
		const { sixSenseLifeGallery, currentScreenWidth } = this.props;
		const { selectedGridIndex } = this.state;
		return (
			<div className='life-grid-container'>
				<div className='life-grid-wrapper'>
					{sixSenseLifeGallery.map((item, i) => (
						<img src={getImagePath(item.imgPath)} key={i} className='life-grid-item' alt=''
							style={
								{
									transform: `${currentScreenWidth < 769 ? `translate3d(-${selectedGridIndex * 100}%, 0px, 0px)` : 'none'}`,
									transitionDuration: '350ms',
								}
							}
						/>
					))}
				</div>
				{currentScreenWidth < 769 && <ul className='control-circle-dots'>
					{sixSenseLifeGallery.map((item, i) => (
						<li
							key={i}
							className={`circle-dot ${selectedGridIndex === i && 'selected'}`}
							style={{
								background: selectedGridIndex === i ? "radial-gradient(#1ca7e5, #65d7c8)" : '#eeeff2',
								opacity: selectedGridIndex === i ? 1 : .3
							}}
							onClick={() => this.handleChangeGrid(i)} />
					))}
				</ul>}
			</div>
		);
	};

	getJoinUsSection = () => {
		const { joinUsTitle, sixSenseAdvantagesGallery, currentScreenWidth } = this.props;
		const { selectedCardIndex } = this.state;
		return (
			<div className='join-us-block'>
				<img src={getImagePath('/section-splitter-icon.svg')} className='join-us-section-splitter' alt='' />
				<span className='join-us-title'>{joinUsTitle}</span>
				<div className='join-us-advantage-container'>
					{sixSenseAdvantagesGallery.map((item, i) => (
						<div
							key={i}
							className='each-advantage-wrapper'
							style={
								{
									transform: `${currentScreenWidth < 769 ? `translate3d(-${selectedCardIndex * 100}%, 0px, 0px)` : 'none'}`,
									transitionDuration: '350ms',
								}
							}
						>
							<div className='each-advantage-block'>
								<img src={getImagePath(item.imgPath)} className='advantage-icon' alt='' />
								<div className='advantage-item-wrapper'>
									<span className='advantage-title'>{item.title}</span>
									<span className='advantage-description'>{item.description}</span>
								</div>
							</div>
						</div>
					))}
				</div>
				{currentScreenWidth < 769 && <ul className='control-circle-dots'>
					{sixSenseAdvantagesGallery.map((item, i) => (
						<li
							key={i}
							className={`circle-dot ${selectedCardIndex === i && 'selected'}`}
							style={{
								background: selectedCardIndex === i ? "radial-gradient(#1ca7e5, #65d7c8)" : '#eeeff2',
								opacity: selectedCardIndex === i ? 1 : .3
							}}
							onClick={() => this.handleChangeCard(i)} />
					))}
				</ul>}
				<div className='employ-opinion-wrapper'>
					{currentScreenWidth > 768 ? <EmployOpinion currentScreenWidth={currentScreenWidth} /> : <ResponsiveEmployOpinion />}
				</div>
			</div>
		);
	};

	render() {
		const { title, subTitle, description, currentScreenWidth, descriptionResize } = this.props;
		const { hasShowDesFull } = this.state;
		let currentDescData = hasShowDesFull ? description : currentScreenWidth < 769 ? descriptionResize : description;
		return (
      <div
        className="life-main-block"
        style={{
          backgroundImage: 'url("' + getImagePath("/six-life-bg.svg") + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
          backgroundSize: currentScreenWidth > 768 ? "16%" : "35%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: currentScreenWidth > 768 ? "0% -7%" : "-37% -6%"
        }}
      >
        <img
          src={getImagePath("/section-splitter-icon.svg")}
          className="life-section-splitter"
          alt=""
        />
        <span className="life-title-block">{title}</span>
        <span className="life-subtitle-block">{subTitle}</span>
        <div className="life-description-block">
          {currentDescData.map((context, i) => (
            <span className="life-description-context" key={i}>
              {context}
            </span>
          ))}
          {currentScreenWidth < 769 && (
            <div className="read-more" onClick={this.handleDesResize}>
              {hasShowDesFull ? `-Read less` : `+Read more`}
            </div>
          )}
        </div>
        {this.getSixSenseLifeGrid()}
        {this.getJoinUsSection()}
      </div>
    );
	}
}

SixSenseLife.defaultProps = {
	title: 'Life At SixSense',
	subTitle: 'Be a Revolutionary',
	descriptionResize: ['At SixSense, we are passionate to lead a breakthrough in the next generation of smarter manufacturing. We hope to build a team where each member is empowered to direct the vision, growth, and future of the company.'],
	description: [
		'At SixSense, we are passionate to lead a breakthrough in the next generation of smarter manufacturing. We hope to build a team where each member is empowered to direct the vision, growth, and future of the company.',
		'We love to ideate on challenging problems, brainstorm unique solutions, and solve open problems using the most transformative advances in technology. If you too are keen to unlock the potential of AI in the fourth industrial revolution, join us in our endeavors!'
	],
	joinUsTitle: 'Why Join Us',
	sixSenseAdvantagesGallery: [
		{
			title: 'Growth',
			description:
				'Your interests matters to us. If you own your responsibilities, we will empower your growth.',
			imgPath: '/join-growth.svg'
		},
		{
			title: 'Flexible Hours',
			description: 'We prize productivity over desk-bound routine. Work at a time that suits you best.',
			imgPath: '/join-hours-new.svg'
		},
		{
			title: 'Work-life Balance',
			description: 'Work hard, Party harder! Enjoy evenings with us over board games, and weekend getaways!',
			imgPath: '/join-work-life-new.svg'
		},
		{
			title: 'Healthcare',
			description: 'Stay fit with unlimited access to gym, pool and a planned insurance plan just for you',
			imgPath: '/join-healthcare.svg'
		}
	],
	sixSenseLifeGallery: [
		{
			id: 1,
			imgPath: '/Gallery/sixsense-gallery-1.jpg'
		},
		{
			id: 2,
			imgPath: '/Gallery/sixsense-gallery-2.jpg'
		},
		{
			id: 3,
			imgPath: '/Gallery/sixsense-gallery-3.jpg'
		},
		{
			id: 4,
			imgPath: '/Gallery/sixsense-gallery-4.jpg'
		},
		{
			id: 5,
			imgPath: '/Gallery/sixsense-gallery-5.jpg'
		},
		{
			id: 6,
			imgPath: '/Gallery/sixsense-gallery-6.jpg'
		},
		{
			id: 7,
			imgPath: '/Gallery/sixsense-gallery-7.jpg'
		},
		{
			id: 8,
			imgPath: '/Gallery/sixsense-gallery-8.jpg'
		}
	]
};

SixSenseLife.propTypes = {
	description: PropTypes.array
};

export default SixSenseLife;
