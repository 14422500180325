import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import Button from 'common/Button';
import Image from 'react-imgr';
import 'react-imgr/dist/styles.min.css';

class Home extends Component {
	render() {
		const { title, subTitle, currentScreenWidth } = this.props;
		return (
			<div className='home-main-block'>
				<Image
					src={getImagePath(currentScreenWidth > 768 ? '/sixsense-home-bg.png' : '/home-bg-responsive.png')}
					preloadSrc={getImagePath('/sixsense-home-bg_20x20.png')}
					containerStyle={{
						width: '100%',
						height: '100%'
					}}
					initialBlur
					alt='alt tag added here'
					scale
					placeholder='placeholder.jpg'
				// className='home-main-block'
				>
					{/* <div
					className='home-main-block'
					style={{
						backgroundImage: 'url("' + getImagePath('/sixsense-home-bg.png') + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
						backgroundSize: '100% 100%'
					}}
				> */}
					{/* <div className='home-content-block'>
						<img src={getImagePath('/sixsense-logo.svg')} className='home-sixsense-icon' />
						<div className='home-title'>{title}</div>
						<div className='home-sub-title'>{subTitle}</div>
						<div className='home-button'>
							<Button handleButtonClick={this.props.handleButtonClick} />
						</div>
						<img src={getImagePath('/home-down-arrow.svg')} className='home-down-arrow' />
					</div> */}
					{/* </div> */}
				</Image>
				<div className='home-content-block'>
					<img src={getImagePath('/sixsense-logo.svg')} className='home-sixsense-icon' alt="" />
					<div className='home-title'>{title}</div>
					<div className='home-sub-title'>{subTitle}</div>
					<div className='home-button'>
						<Button subClass={'home-know-button'} handleButtonClick={this.props.handleButtonClick} />
					</div>
					<img src={getImagePath('/home-down-arrow.svg')} className='home-down-arrow' alt="" />
				</div>
			</div>
		);
	}
}

Home.defaultProps = {
	title: 'SixSense',
	subTitle: 'AI-powered Defect Analytics Platform for Smart Manufacturing',
	buttonText: 'Know More'
};

Home.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	buttonText: PropTypes.string
};

export default Home;
