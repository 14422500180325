import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { getImagePath } from 'utils/assetsHelper';

/**
 * Courousel DOC's ******Need to remove in future
 *
 * https://www.npmjs.com/package/react-responsive-carousel
 * SandBox demo: https://codesandbox.io/s/lp602ljjj7
 *
 * Customizable Demo Docs: http://react-responsive-carousel.js.org/#demos,
 * http://react-responsive-carousel.js.org/storybook/index.html?selectedKind=Carousel&selectedStory=custom%20transition%20time%20%281000ms%29&full=0&down=1&left=1&panelRight=0&downPanel=kadirahq%2Fstorybook-addon-actions%2Factions-panel
 *
 */

class CarouselMonials extends Component {
	getCarouselData = () => {
		const { peopleSayData } = this.props;

		return peopleSayData.map((item, i) => {
			return (
        <div className="each-image" key={i}>
          <div className="image-inner-block">
            <p className="item-description">{item.description}</p>
            <div
							style={{
								textAlign: "left",
								paddingBottom: item.style.paddingBottom
							}}
						>
              <span
                className="person-name"
                style={{
                  paddingBottom: item.personExpIcon ? "0px" : "35px"
                }}
              >
                {item.name}
              </span>
              {item.personExpIcon && (
                <img
                  src={getImagePath(item.personExpIcon)}
                  className="person-exp-icon"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      );
		});
	};

	getPreviousArrowIcon = () => {
		return <img src={getImagePath('/previous-icon.png')} alt='previous' className='previous-arrow' />;
	};

	render() {
		return (
			<div className='testimonial-carousel-container'>
				<Carousel
					//width={'80%'}
					infiniteLoop={true}
					//autoPlay
					interval={3000}
					transitionTime={350}
					// swipeable
					showStatus={false}
					//centerMode
					//centerSlidePercentage={60}
					showIndicators={window.innerWidth < 771 ? true : false}
					dynamicHeight
					useKeyboardArrows={true}
					emulateTouch={true}
					showThumbs={false} //Remove thumnnails
					prevArrowTemplate={this.getPreviousArrowIcon()}
					nextArrowTemplate={this.getPreviousArrowIcon()}
				>
					{/* {this.getCarouselData()} */}
					{/* <div
						className='each-image'
						// style={{
						// 	width: '60%'
						// }}
					/> */}
					{this.getCarouselData()}
					{/* <div className='each-image'>
						<img src='http://lorempixel.com/output/cats-q-c-640-480-2.jpg' />
						<p className='legend'>Legend 2</p>
					</div> */}
				</Carousel>
			</div>
		);
 	}
}

CarouselMonials.propTypes = {
	peopleSayData: PropTypes.array
};

export default CarouselMonials;
