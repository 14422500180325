import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import Swipe from 'common/SwipeHandler';

class ResponsiveEmployOpinion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCardIndex: 0
        };
    }

    handleChangeCard = (index) => {
        this.setState({ selectedCardIndex: index });
    }

    componentDidMount() {
        if (document.getElementsByClassName('res-employ-opinion-wrapper')) {
            var swiper = new Swipe(document.getElementsByClassName('res-employ-opinion-wrapper')[0]);
            swiper.onLeft(() => {
				if (this.props.responsiveCorouselData.length - 1 > this.state.selectedCardIndex)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex + 1});
			});
			swiper.onRight(() => {
				if (this.state.selectedCardIndex > 0)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex - 1});
            });
            swiper.run();
        }
    }

    render() {
        const { responsiveCorouselData } = this.props;
        const { selectedCardIndex } = this.state;
        return (
            <div className='res-employ-opinion-container'>
                <div className='res-employ-opinion-wrapper'>
                    {responsiveCorouselData.map((eachOpinion, index) => (
                        <div
                            key={index}
                            className='opinion-inner-wrapper'
                            style={
                                {
                                    transform: `translate3d(-${selectedCardIndex * 100}%, 0px, 0px)`,
                                    transitionDuration: '350ms',
                                }
                            }
                        >
                            <div className='opinion-inner-block'
                            >
                                <div className='opinion-header-block'>
                                    <img src={getImagePath(eachOpinion.imgPath)} className='person-icon' alt='' />
                                    <div className='person-content-wrapper'>
                                        <span className='person-name'>{eachOpinion.personName}</span>
                                        <span className='person-designation'>{eachOpinion.designation}</span>
                                    </div>
                                </div>
                                <p className='opinion-description'>{eachOpinion.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <ul className='control-circle-dots'>
                    {responsiveCorouselData.map((item, i) => (
                        <li
                            key={i}
                            className={`circle-dot ${selectedCardIndex === i && 'selected'}`}
                            style={{
                                background: selectedCardIndex === i ? "radial-gradient(#1ca7e5, #65d7c8)" : '#eeeff2',
                                opacity: selectedCardIndex === i ? 1 : .3
                            }}
                            onClick={() => this.handleChangeCard(i)} />
                    ))}
                </ul>
            </div>
        )
    }
}

ResponsiveEmployOpinion.defaultProps = {
    responsiveCorouselData: [
        {
            id: 1,
            imgPath: '/Employees/kai.png',
            designation: 'Business Intern',
            description:
                'I tremendously enjoyed my time at SixSense doing business development. I was given full ownership of my responsibilities, and got to shape my jobscope according to my areas of interest too. The team is talented and committed, and working with them has been eye-opening and very fulfilling!',
            personName: 'Kai Xin'
        },
        {
            id: 2,
            imgPath: '/Employees/prasant_new.jpg',
            designation: 'Data Science Intern',
            description:
                'Being a part of sixsense.ai provides great growth and development opportunities as well as diverse range of challenging projects to work on. The things I appreciate most about the company are flexible working environment and chances to employ cutting edge AI techniques to solve problems. Working here is pretty fun and exciting.',
            personName: 'Prashant Anand'
        },
        {
            id: 4,
            imgPath: '/Employees/utsav.png',
            designation: 'Data Science Intern',
            description:
                'Working at SixSense, helping them build their initial product was an enriching experience. It was great applying machine learning techniques to a new domain and seeing the results in a real world scenario. The team was helpful and enjoyable to work with!',
            personName: 'Utsav'
        },
        {
            id: 4,
            imgPath: '/Employees/sarat.jpg',
            designation: 'Data Science Intern',
            description:
                'Six sense provides an ideal platform for data science aspirants to solve the real life problems with data science solutions. Interns get real time projects with real responsibilities and get to contribute to the solution development. Work life balance has been great with flexible work timings and with the option of work from home. Team is very supportive and encouraging.',
            personName: 'Sarath'
        }
    ]
}

ResponsiveEmployOpinion.propTypes = {
    responsiveCorouselData: PropTypes.array
}

export default ResponsiveEmployOpinion;