import React from 'react';
import './index.scss';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { Carousel } from 'react-responsive-carousel';
import { getImagePath } from 'utils/assetsHelper';
import SixSenseCarousel from 'components/Testimonials';
import Team from 'components/Team';

const TeamAndTestimonials = ({ currentScreenWidth, getTeamRef, handleButtonRedirection }) => {
	return (
		<div
			className='tandt-wrappper'
			style={{
				backgroundImage: 'url("' + getImagePath('/founters-bg.svg') + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
				//backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				backgroundSize: '40% 40%',
				backgroundPositionY: '35%'
				//backgroundPositionX: '100%'
			}}
		>
			<SixSenseCarousel currentScreenWidth={currentScreenWidth}/>
			<Team currentScreenWidth={currentScreenWidth} getTeamRef={getTeamRef} handleButtonRedirection={handleButtonRedirection} />
		</div>
	)
}

export default React.memo(TeamAndTestimonials);
