import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import Button from 'common/Button';

function Team(props) {
	const { title, fountersInfo, currentScreenWidth } = props;
	let [readMoreData, toggleReadMore] = useState(
		{
			0: false,
			1: false
		}
	);
	const handleLinkdinRedirection = (index = '') => {
		let url;
		if (index === 0) url = 'https://www.linkedin.com/in/akanksha-jagwani-a7489041/';
		else url = 'h	ttps://www.linkedin.com/in/avni-agrawal/';

		window.open(url, '_blank');
	};

	let handleReadMoreToggle = (fIndex = 0) => {
		let currentReadmoreInfo = Object.assign({}, readMoreData);
		currentReadmoreInfo[fIndex] = !currentReadmoreInfo[fIndex];
		toggleReadMore(currentReadmoreInfo);
	}

	return (
		<div
			className='team-main-block'
			id="team-section"
			ref={el => {
				props.getTeamRef(el);
			}}
		// style={{
		// 	backgroundImage: 'url("' + getImagePath('/founters-bg.svg') + '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
		// 	//backgroundSize: 'contain',
		// 	backgroundRepeat: 'no-repeat'
		// 	//backgroundPositionX: '100%'
		// }}
		>
			<img src={getImagePath('/section-splitter-icon.svg')} className='team-section-splitter' alt='' />
			<span className='team-title-block'>{title}</span>
			<div className='founter-overview-container'>
				{fountersInfo.map((item, i) => (
					<div
						className={`each-founter ${readMoreData[i] ? 'normal' : ''}`}
						key={i}
						style={{
							marginRight: i % 2 === 0 ? '26px' : '0'
						}}
					>
						<div className='founder-icon-wrapper'>
							<img src={getImagePath(item.iconUrl)} alt='founter' className='founter-icon' />
							<img
								src={getImagePath('/linDnIcon.svg')}
								alt='founter'
								className='founter-lkndn-icon'
								onClick={() => handleLinkdinRedirection(i)}
							/>
						</div>
						<span className='founter-name'>{item.name}</span>
						<span className='founter-designation' style={{opacity:0.6}}>{item.designation}</span>
						<span className={`founter-description`}>{readMoreData[i] ? item.description : currentScreenWidth < 768 ? item.descResize : item.description}</span>
						<span className='founter-read-more' onClick={() => handleReadMoreToggle(i)}>{readMoreData[i] ? '-Read Less' : '+ Read More'}</span>
					</div>
				))}
			</div>
			<div className='know-more-button-block'>
				<Button text={'More about our team'} subClass='know-more-button'
					handleButtonClick={props.handleButtonRedirection}
				/>
			</div>
		</div>
	);
}

Team.defaultProps = {
	title: 'Meet Our Founders',
	fountersInfo: [
		{
			iconUrl: '/akansha.png',
			name: 'Akanksha Jagwani',
			designation: 'Co-founder and CEO',
			descResize: 'Akanksha is a Mechanical Engineer from IIT (Indian Institute of Technology), and has...',
			description:
				'Akanksha is a Mechanical Engineer from IIT (Indian Institute of Technology), and has worked with many trailblazers including Hyundai Motors, General Electric, Washington University and A*STAR. She now spearheads her own manufacturing revolution with SixSense.'
		},
		{
			iconUrl: '/avani.png',
			name: 'Avni Agrawal',
			designation: 'Co-founder and CTO',
			descResize: 'Avni majored in Computer Science from NIT (National Institute of Technology) and has...',
			description:
				'Avni majored in Computer Science from NIT (National Institute of Technology) and has worked in data teams at leading multinational firms such as Visa and D.E. Shaw. She has a strong foundation in Data Science and is now determined to disrupt manufacturing with AI.'
		}
	]
};

Team.propTypes = {
	title: PropTypes.string,
	fountersInfo: PropTypes.array
};

export default Team;
