import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import Header from 'components/Header';
import Home from 'components/Home';
import AboutUs from 'components/AboutUs';
import AboutWork from 'components/AboutWork';
import Products from 'components/Products';
import Careers from 'components/Careers';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';
import SixSenseLife from 'components/SixSenseLife';
import Investors from 'components/Investors';
import TeamAndTestimonials from 'components/TeamAndTestimonials';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.scrollItems = {
			//Need to change the naming intentation after getting the original design
			home: null,
			about: null,
			aboutwork: null,
			team: null,
			careers: null,
			contact: null,
			peoplesay: null,
			sixsenselife: null
		};
		this.state = {
			scrolledSection: 'home',
			currentScreenWidth: window.innerWidth,
			tapTop: false
		};
		this.calculateAndUpdateScreenWidth();
	}

	calculateAndUpdateScreenWidth = () => {
		window.addEventListener('resize', () => {
			this.setState({ currentScreenWidth: window.innerWidth });
		}, true);
	}

	componentDidMount() {
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 500) {
				const tapTop = true;
				if (tapTop !== this.state.tapTop) {
					this.setState({
						tapTop
					})
				}
			} else {
				const tapTop = false;
				if (tapTop !== this.state.tapTop) {
					this.setState({
						tapTop
					})
				}
			}

			if (this.state.currentScreenWidth > 768) {
				// Get the positions of sections matched to header nav items
				const sectionPositions = [];
				for (let [key, item] of Object.entries(this.scrollItems)) {
					const scrollSection = document.getElementById(item.id);
					if (scrollSection !== null) {
						const obj = {
							sectionName: key,
							position: scrollSection.offsetTop 
						};
						sectionPositions.push(obj);
					}
				}

				// Get the section for the current scolling matched to header hav item
				const windowPageYOffset = window.pageYOffset + 2;
				let scrolledSection = 'home';
				for (let index = 0; index < sectionPositions.length; index ++) {
					if (index === sectionPositions.length - 1) {
						const position = sectionPositions[index];
						if (windowPageYOffset >= position.position) {
							scrolledSection = position.sectionName;
							break;
						}
					} else {
						const firstPosition = sectionPositions[index];
						const secondPosition = sectionPositions[index+1];
						if (firstPosition.position <= windowPageYOffset && windowPageYOffset < secondPosition.position) {
							scrolledSection = firstPosition.sectionName;
							break;
						}
					}
				}

				if (scrolledSection !== this.state.scrolledSection) {
					this.setState({
						scrolledSection
					})
				}
			}
		});
	}

	handleScroll = (scrollItem = '') => {
		let selectedScrollItem = this.scrollItems[scrollItem];
		if (selectedScrollItem !== undefined && selectedScrollItem !== null)
			selectedScrollItem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
	};

	handleToHome = () => {
		this.handleScroll('home');
	}

	render() {
		const { currentScreenWidth, scrolledSection, tapTop } = this.state;
		const { headerItems } = this.props;
		return (
			<div className='layout-main-container'>
				<div className='header-section'>
					<Header contentItems={headerItems} triggerHandleScroll={this.handleScroll} scrolledSection={scrolledSection}/>
				</div>
				<div className='main-content-section'>
					<section
						className='each-section'
						id='home-section'
						ref={el => {
							this.scrollItems['home'] = el;
						}}
					>
						<Home currentScreenWidth={currentScreenWidth} handleButtonClick={() => this.handleScroll('about')} />
					</section>
					<section
						className='each-section'
						id='aboutus-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['about'] = el;
						}}
					>
						<AboutUs currentScreenWidth={currentScreenWidth} />
					</section>
					<section
						className='each-section'
						id='aboutwork-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['aboutwork'] = el;
						}}
					>
						<AboutWork currentScreenWidth={currentScreenWidth} handleButtonClick={() => this.handleScroll('contact')} />
					</section>
					<section
						className='each-section'
						style={{
							height: 'auto'
						}}
					>
						<Products currentScreenWidth={currentScreenWidth} />
					</section>

					<section
						className='each-section'
						id='teamandtestimonials-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['peoplesay'] = el;
						}}
					>
						<TeamAndTestimonials
							currentScreenWidth={currentScreenWidth}
							getTeamRef={teamRef => (this.scrollItems['team'] = teamRef)}
							handleButtonRedirection={() => this.handleScroll('sixsenselife')}
						/>
					</section>

					<section
						className='each-section'
						id='investors-section'
						style={{
							height: 'auto'
						}}
					>
						<Investors currentScreenWidth={currentScreenWidth} />
					</section>
					<section
						className='each-section'
						id='sixsenselife-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['sixsenselife'] = el;
						}}
					>
						<SixSenseLife currentScreenWidth={currentScreenWidth} />
					</section>
					<section
						className='each-section'
						id='careers-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['careers'] = el;
						}}
					>
						<Careers currentScreenWidth={currentScreenWidth} />
					</section>
					<section
						className='each-section'
						id='contactus-section'
						style={{
							height: 'auto'
						}}
						ref={el => {
							this.scrollItems['contact'] = el;
						}}
					>
						<ContactUs />
					</section>
					<section
						className='each-section'
						id='footer-section'
						style={{
							height: 'auto'
						}}
					>
						<Footer
							handleFooterNavigation={this.handleScroll}
							tapTop={tapTop}
							handleToHome={this.handleToHome}
						/>
					</section>
				</div>
			</div>
		);
	}
}

Layout.defaultProps = {
	headerItems: [
		{
			label: 'Home',
			value: 'home'
		},
		{
			label: 'About Us',
			value: 'about'
		},
		{
			label: 'Products',
			value: 'aboutwork'
		},
		{
			label: 'Team',
			value: 'team'
		},
		{
			label: 'Careers',
			value: 'careers'
		},
		{
			label: 'Contact Us',
			value: 'contact'
		}
	]
};

Layout.propTypes = {
	headerItems: PropTypes.array
};

export default Layout;
