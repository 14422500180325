import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import StateButton from 'common/StateButton';
import axios from 'axios';
import Modal from 'react-responsive-modal';

class ContactUs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			phone: '',
			email: '',
			message: '',
			warningInfo: {},
			modalOpen: false
		};
	}

	handleFormUpdate = (actionType, e) => {
		const { actionTypes } = this.props;
		let inputValue = e.target.value;
		if (actionType === actionTypes.UPDATE_NAME) this.setState({ name: inputValue });
		else if (actionType === actionTypes.UPDATE_PHONE) this.setState({ phone: inputValue });
		else if (actionType === actionTypes.UPDATE_EMAIL) this.setState({ email: inputValue });
		else if (actionType === actionTypes.UPDATE_MESSAGE) this.setState({ message: inputValue });
	};

	handleRedirection = actionType => {
		let url;
		if (actionType === 'LINKEDIN') url = 'https://www.linkedin.com/company/sixsense-pte-ltd';
		if (actionType === 'YOUTUBE') url = 'https://www.youtube.com/watch?v=2Py4frlOmTw';
		window.open(url, '_blank');
	};

	handleCloseModal = () => {
		if (this.state.messageDelivered) {
			this.resetForm();
		}
		this.setState({
			modalOpen: false
		});
	}

	handleSendQuery = () => {
		const dev_url = 'http://localhost:3100/send';
		const prod_url = 'https://mailer.sixsense.ai/send';

		return new Promise((success, fail) => {			
			let { name, phone, email, message } = this.state;

			let validationData = {
				name: name === '' ? true : false,
				phone: phone === '' ? true : false,
				email: email === '' ? true : false
			};

			if (Object.values(validationData).includes(true)) {
				this.setState({ warningInfo: validationData });
				fail('Something is wrong!');
			} else {
				axios({
					method: "POST",
					url: prod_url,
					data: {
						name,
						email,
						phone,
						message
					}
				}).then((response) => {
					if (response.data.message === 'success') {
						this.setState({
							messageDelivered: true,
							modalOpen: true
						});
						success('Everything went right!');
					} else if (response.data.msg === 'failed') {
						this.setState({
							messageDelivered: false,
							modalOpen: true
						});
						fail('Something is wrong!');
					}
				});
			}
		});
	};

	resetForm = () => {
		this.setState({
			name: '',
			phone: '',
			email: '',
			message: '',
			warningInfo: {}
		});
	}

	getContactForm = () => {
		const { actionTypes } = this.props;
		let { name, phone, email, message, warningInfo } = this.state;
		return (
			<div className='contact-us-form'>
				<div className='contact-name'>
					<input
						type='text'
						value={name}
						placeholder='Name'
						className='input-name'
						onChange={e => this.handleFormUpdate(actionTypes.UPDATE_NAME, e)}
					/>
					{warningInfo.name && <span className='validation-block'>{'*Please enter a name.'}</span>}
				</div>
				<span className='contact-inner-block'>
					<div className='contact-phone'>
						<input
							type='number'
							value={phone}
							placeholder='Phone No.'
							className='input-phone'
							required
							onChange={e => this.handleFormUpdate(actionTypes.UPDATE_PHONE, e)}
						/>
						{warningInfo.phone && <span className='validation-block'>{'*Enter a valid Phone no.'}</span>}
					</div>

					<div className='contact-email'>
						<input
							type='mail'
							value={email}
							placeholder='Email'
							className='input-email'
							onChange={e => this.handleFormUpdate(actionTypes.UPDATE_EMAIL, e)}
						/>
						{warningInfo.email && <span className='validation-block'>{'*Enter a valid Email.'}</span>}
					</div>
				</span>
				<textarea
					rows='4'
					cols='50'
					value={message}
					placeholder='Add Message'
					className='input-message'
					onChange={e => this.handleFormUpdate(actionTypes.UPDATE_MESSAGE, e)}
				/>
				<div className='contact-submit'>
					<StateButton id="sendButton" text='Send' onClick={this.handleSendQuery} />
				</div>
			</div>
		);
	};

	render() {
		const { title } = this.props;
		const { name, modalOpen, messageDelivered } = this.state;

		return (
			<div className='contact-main-block'>
				<img src={getImagePath('/section-splitter-icon.svg')} className='contact-section-splitter' alt='' />
				<span className='contact-title-block'>{title}</span>
				<div className='contact-overview-container'>
					<div className='contact-address-block'>
						<div className='contact-address'>
							<span className='contact-inner-block'>
								<img src={getImagePath('/telephone-icon.svg')} alt='phone' className='phone-icon' />
								<span className='address-text'>{'+65 94860203'}</span>
							</span>
							<span className='contact-inner-block'>
								<img src={getImagePath('/mail-icon.svg')} alt='email' className='mail-icon' />
								<span className='address-text'>{'hello@sixsense.ai'}</span>
							</span>
							<span className='contact-inner-block'>
								<img
									src={getImagePath('/linked-in-icon.svg')}
									alt='email'
									className='linIn-icon'
									style={{ cursor: 'pointer' }}
									onClick={() => this.handleRedirection('LINKEDIN')}
								/>
								<span className='address-text'
									style={{ cursor: 'pointer' }}
									onClick={() => this.handleRedirection('LINKEDIN')}
								>{'SixSense'}</span>
							</span>
							<span className='contact-inner-block'>
								<img
									src={getImagePath('/address--location-icon.svg')}
									alt='location'
									className='location-icon'
								/>
								<span className='address-text'>{'WeWork 380 Jalan Besar, Singapore 209000'}</span>
							</span>
							{/* <div className='location-map-block'> */}
							{/* <SimpleMapPage /> */}
							<img src={getImagePath('/sixsense-location-map.png')} className='office-location-image' alt='' />
							{/* </div> */}
						</div>
					</div>
					<div className='contact-form-block'>{this.getContactForm()}</div>
				</div>

				<Modal
					open={modalOpen}
					onClose={this.handleCloseModal}
					center
					animationDuration={1000}
				>
					<div className='modal-main-block'>
						<img src={getImagePath('/section-splitter-icon.svg')} className='contact-section-splitter' alt='' />
						<div className="modal-title">Hi, {name}</div>
						{ messageDelivered
							? <div className="modal-description">
									Thanks for your interest in <span>SixSense</span>!<br/>
									We received your message and we'll get back to you as soon as possible.<br/><br/>
								</div>
							: <div className="modal-description">
									Something went wrong while sending your message. Please try again.
								</div>
						}
						<div className='f-social-media-icon-block'>
							<img src={getImagePath('/six-sense-logo-old.svg')} alt='logo' className='f-fb-icon' />
							<div className='f-tr-icon'>SixSense</div>
							<img
								src={getImagePath('/ytbe-icon.svg')}
								alt='yb'
								className='f-ytb-icon'
								onClick={() => this.handleRedirection('YOUTUBE')}
							/>
						</div>
					</div>
        </Modal>
			</div>
		);
	}
}

ContactUs.defaultProps = {
	title: 'Get In Touch!',
	actionTypes: {
		UPDATE_NAME: 'UPDATE_NAME',
		UPDATE_PHONE: 'UPDATE_PHONE',
		UPDATE_EMAIL: 'UPDATE_EMAIL',
		UPDATE_MESSAGE: 'UPDATE_MESSAGE'
	}
};

ContactUs.propTypes = {
	title: PropTypes.string,
	actionTypes: PropTypes.object
};

export default ContactUs;
