import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getImagePath } from 'utils/assetsHelper';
import Swipe from 'common/SwipeHandler';

/**
 * Courousel DOC's ******Need to remove in future
 *
 * https://www.npmjs.com/package/react-responsive-carousel
 * SandBox demo: https://codesandbox.io/s/lp602ljjj7
 *
 * Customizable Demo Docs: http://react-responsive-carousel.js.org/#demos,
 * http://react-responsive-carousel.js.org/storybook/index.html?selectedKind=Carousel&selectedStory=custom%20transition%20time%20%281000ms%29&full=0&down=1&left=1&panelRight=0&downPanel=kadirahq%2Fstorybook-addon-actions%2Factions-panel
 *
 */

class SwipeMonials extends Component {
	constructor(props) {
    super(props);
    this.state = {
      selectedCardIndex: 0
    };
  }

	componentDidMount() {
		if (document.getElementsByClassName('testimonial-wrapper')) {
			var swiper = new Swipe(document.getElementsByClassName('testimonial-wrapper')[0]);
			swiper.onLeft(() => {
				if (this.props.peopleSayData.length - 1 > this.state.selectedCardIndex)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex + 1});
			});
			swiper.onRight(() => {
				if (this.state.selectedCardIndex > 0)
					this.setState({ selectedCardIndex: this.state.selectedCardIndex - 1});
			});
			swiper.run();
		}
	}
	
	handleChangeCard = (index) => {
		this.setState({ selectedCardIndex: index });
  }

	render() {
		const { peopleSayData } = this.props;
		const { selectedCardIndex } = this.state;

		return (
			<div className='testimonial-carousel-container'>
				<div className='testimonial-wrapper'>
					{peopleSayData.map((item, i) => (
						<div
							key={i}
							className='opinion-inner-wrapper'
							style={
								{
									transform: `translate3d(-${selectedCardIndex * 100}%, 0px, 0px)`,
									transitionDuration: '350ms',
								}
							}
						>
							<div className="each-image">
								<div className="image-inner-block">
									<p className="item-description">{item.description}</p>
									<div
										style={{
											textAlign: "left",
											paddingBottom: item.style.paddingBottom
										}}
									>
										<span
											className="person-name"
											style={{
												paddingBottom: item.personExpIcon ? "0px" : "35px"
											}}
										>
											{item.name}
										</span>
										{item.personExpIcon && (
											<img
												src={getImagePath(item.personExpIcon)}
												className="person-exp-icon"
												alt=""
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<ul className='control-circle-dots'>
					{peopleSayData.map((item, i) => (
						<li
							key={i}
							className={`circle-dot ${selectedCardIndex === i && 'selected'}`}
							style={{
								background: selectedCardIndex === i ? "radial-gradient(#1ca7e5, #65d7c8)" : '#eeeff2',
								opacity: selectedCardIndex === i ? 1 : .3
							}}
							onClick={() => this.handleChangeCard(i)} />
					))}
				</ul>
			</div>
		);
 	}
}

SwipeMonials.propTypes = {
	peopleSayData: PropTypes.array
};

export default SwipeMonials;
