import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { noop } from 'utils';

export default function Button(props) {
	const { subClass, text, styles } = props;
	return (
		<div className={`button-wrapper ${subClass}`} style={{ ...styles }} onClick={props.handleButtonClick}>
			{text}
		</div>
	);
}

Button.defaultProps = {
	subClass: 'predefined',
	styles: {}, //For customising styles with user defined styles
	text: 'Know More',
	handleButtonClick: noop
};

Button.propTypes = {
	subClass: PropTypes.string,
	styles: PropTypes.object,
	text: PropTypes.string,
	handleButtonClick: PropTypes.func
};
