import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { getImagePath } from 'utils/assetsHelper';
import Swipe from 'common/SwipeHandler';

function Products(props) {
	const { title, productsInfo, currentScreenWidth } = props;

	const [selectedCardIndex, updateCardIndex] = useState(0);

	const handleChangeCard = (index) => {
		updateCardIndex(index);
	}

	useEffect(() => {
		if (document.getElementsByClassName('products-overview-container')) {
			var swiper = new Swipe(document.getElementsByClassName('products-overview-container')[0]);
			swiper.onLeft(() => {
				if (productsInfo.length - 1 > selectedCardIndex)
					updateCardIndex(selectedCardIndex + 1);
			});
			swiper.onRight(() => {
				if (selectedCardIndex > 0)
					updateCardIndex(selectedCardIndex - 1);
			});
			swiper.run();
		}
	})

	return (
		<div className='products-main-block'>
			<img src={getImagePath('/section-splitter-icon.svg')} className='product-section-splitter' alt='' />
			<span className='products-title-block'>{title}</span>
			<div className='products-overview-container'>
				{productsInfo.map((item, i) => {
					return (
						<div
						 	key={i}
							className='each-product-wrpper'
							style={
								{
									transform: `${currentScreenWidth < 769 ? `translate3d(-${selectedCardIndex * 100}%, 0px, 0px)` : 'none'}`,
									transitionDuration: '350ms',
								}
							}
						>
							<div className='each-product'>
								<img src={getImagePath(item.iconUrl)} alt='product' className='product-icon' />
								<div className='product-item-wrapper'>
									<span className='product-item-name'>{item.name}</span>
									<span className='product-item-description'>{item.description}</span>
								</div>
							</div>
						</div>

					);
				})}
			</div>
			{currentScreenWidth < 769 && <ul className='control-circle-dots'>
				{productsInfo.map((item, i) => (
					<li
						key={i}
						className={`circle-dot ${selectedCardIndex === i && 'selected'}`}
						style={{
							background: selectedCardIndex === i ? "radial-gradient(#1ca7e5, #65d7c8)" : '#eeeff2',
							opacity: selectedCardIndex === i ? 1 : .3
						}}
						onClick={() => handleChangeCard(i)} />
				))}
			</ul>}
		</div>
	);
}

Products.defaultProps = {
	title: 'We Connect Seamlessly',
	productsInfo: [
		{
			iconUrl: '/product-real-time.svg',
			name: 'Real-time Integration',
			description: 'Integrate our insights into your existing solutions or build new internal solutions'
		},
		{
			iconUrl: '/dashboard-icon.png',
			name: 'Dashboard Analytics',
			description: 'Intuitive GUI provides visualisation of results, and allows for user feedback'
		},
		{
			iconUrl: '/product-custom.svg',
			name: 'Email & Mobile Alerts',
			description: 'Receive immediate notifications of important events on factory floor'
		}
	]
};

Products.propTypes = {
	title: PropTypes.string,
	productInfo: PropTypes.array
};

export default Products;
