import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { getImagePath } from "utils/assetsHelper";

class AboutUs extends Component {
  render() {
    const { aboutUstitle, currentScreenWidth } = this.props;
    return (
      <div
        className="about-main-block"
        style={{
          backgroundImage:
            'url("' +
            getImagePath(
              currentScreenWidth > 768 ? "/about-us-nodes.svg" : "/about-us-bg-responsive.png"
            ) +
            '")', //searchText == '' ? 'url("' + getImagePath('/search_icon.png') + '")' : 'none',
          backgroundSize: `${currentScreenWidth > 768 ? "25%" : "auto"}`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: currentScreenWidth > 768 ? "98% 4%" : "100% 4%",
        }}
      >
        <div className="about-content-block">
          <img
            src={getImagePath("/section-splitter-icon.svg")}
            className="section-splitter-1"
            alt=""
          />
          <div className="about-author-container">
            <span className="about-title">{aboutUstitle}</span>
            <div className="about-inner-content-block">
              <span className="about-content-sub-text1">
                At SixSense, we are solving the problem of high cost of visual quality inspection in
                manufacturing. We are doing this by substituting the decade-old ways of manual and
                rule based inspection with smarter AI based defect identification. We help
                manufacturers scan millions of images at high speed and identify defects at scale
                using cutting- edge technologies such as computer vision and deep learning.
              </span>
              <span className="about-content-sub-text2">
                We are targeting this problem in high tech industries, with semiconductor
                manufacturing as our first target vertical and have global customers across US,
                Europe, and South East Asia.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AboutUs.defaultProps = {
  aboutUstitle: "Who We Are",
};

AboutUs.propTypes = {
  aboutUstitle: PropTypes.string,
};

export default AboutUs;
