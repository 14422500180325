import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'utils';
import './index.scss';

class StateButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'default',
      message: '',
      messageShow: false,
    }
  }

  handleOnClick = () => {
    this.setState(
      {
        status: 'processing',
      },
      () => {
        this.props
          .onClick()
          .then(res => {
            this.setState({
              status: 'success',
              message: res,
            })
          })
          .catch(err => {
            this.setState({
              status: 'fail',
              message: err,
            })
          })
          .finally(() => {
            this.setState(
              {
                messageShow: true,
              },
              () => {
                this.setState({
                  messageShow: false,
                  status: 'default',
                })
              }
            )
          })
      }
    )
  }
  render() {
    const { subClass, text } = this.props;
    return (
      <button
        id={this.props.id}
        className={`button-wrapper btn-multiple-state ${subClass} ${classnames({
          'show-spinner': this.state.status === 'processing',
          'show-success': this.state.status === 'success',
          'show-fail': this.state.status === 'fail',
        })}`}
        onClick={this.handleOnClick}
        disabled={this.state.status !== 'default'}
      >
        <span className="spinner d-inline-block">
          <span className="bounce1" />
          <span className="bounce2" />
          <span className="bounce3" />
        </span>
        <span className="label">{text}</span>
      </button>
    )
  }
}

StateButton.defaultProps = {
	subClass: 'predefined',
	text: 'Know More',
	handleButtonClick: noop
};

StateButton.propTypes = {
	subClass: PropTypes.string,
	text: PropTypes.string,
	handleButtonClick: PropTypes.func
};

export default StateButton

